import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import { ReactComponent as Question } from '../resources/img/question.svg';

import {connect} from 'react-redux'
import { updateTimepointTask, updateExtraTask } from '../../actions/answerModelAction';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix=""
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

class InputFieldClient extends React.Component {
    state = {
        perWeek: '',
        level: ''
    }

    handleChange = (name, event) => {
        this.setState({
            ...this.state,
            [name]: event.target.value
        })

        this.props.store(this.props.title, name, event.target.value);
    };

    savePerWeek(value){
        const { title, timepoint, answerModel, extraTasks } = this.props;
        const { selection } = answerModel;
        const obj = {
                frequency: value,
                selection: selection, 
            }
        if(extraTasks){
            this.props.updateExtraTask(title, obj);
        }else{
            this.props.updateTimepointTask(timepoint, title, obj);
        }
    }

    saveSelection(value){
        const { title, timepoint, answerModel, extraTasks } = this.props;
        const { frequency } = answerModel;
        const obj = {
                frequency: frequency,
                selection: value, 
            }
        if(extraTasks){
            this.props.updateExtraTask(title, obj);
        }else{
            this.props.updateTimepointTask(timepoint, title, obj);
        }
       
    }

    checkCorrect(frequency, selection, hasFrequency, extraTasks){

        const frequencyDefault = extraTasks ? "-" : 0

        if(hasFrequency){
            if(frequency === frequencyDefault && selection !== "-") return false;
            if(frequency !== frequencyDefault && selection === "-") return false;
        }
        
        return true
    }

    render() {
        const isTestKommun = this.props.backendDataReducer.selectedMunicipaly === "Testkommun";
        const { title, values, index, canEdit, timepoint, answerModel, extraTasks, error } = this.props;
        const { frequency, selection } = answerModel;
        const { selectedMunicipaly } = this.props.backendDataReducer;
        const { showSettings, showDescription } = this.props.backendDataReducer.data[selectedMunicipaly].formSettings;
        const correct = this.checkCorrect(frequency, selection, values.hasFrequency, extraTasks);
        return (
            <Grid container spacing={3} style={{paddingRight: 20,}}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle1" color="inherit" noWrap display="block" style={{ marginTop: 16 }}>
                        {title} 
                    </Typography>
                     {showDescription && 
                        <Typography variant="caption" color="inherit" noWrap display="block" style={{ marginTop: 0 }}>
                            {values.description}
                        </Typography>
                     }
                </Grid>
                {values.hasFrequency ?
                    <Grid item xs={12} sm={4}>
                     {extraTasks ? 
                        <FormControl>
                            <Select
                                placeholder=""
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={frequency}
                                onChange={(event) => this.savePerWeek(event.target.value)}
                            >
                                <MenuItem value={"-"}>-</MenuItem>
                                {
                                    isTestKommun ? (
                                        <MenuItem value={"Varje vecka"}>Varje vecka</MenuItem>
                                    ) : null
                                }                                
                                <MenuItem value={"Varannan vecka"}>Varannan vecka</MenuItem>
                                <MenuItem value={"Var tredje vecka"}>Var tredje vecka</MenuItem>
                                {
                                    !isTestKommun ? (<MenuItem value={"Var fjärde vecka"}>Var fjärde vecka</MenuItem>) : null
                                }                                
                            </Select>
                            <FormHelperText>{extraTasks ? "Frekvens" : "Besök per vecka"}</FormHelperText>
                        </FormControl>

                        :
                        <FormControl>
                            <Select
                                placeholder=""
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={frequency}
                                onChange={(event) => this.savePerWeek(event.target.value)}
                            >
                                <MenuItem value={0}>0</MenuItem>
                                { !isTestKommun ? (
                                    <MenuItem value={0.25}>0.25</MenuItem>
                                ) : null}
                                { !isTestKommun ? (
                                    <MenuItem value={0.33}>0.33</MenuItem>
                                ) : null}
                                
                                <MenuItem value={0.5}>0.5</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                            </Select>
                            <FormHelperText>{extraTasks ? "Frekvens" : "Besök per vecka"}</FormHelperText>
                        </FormControl>
                    }
                    </Grid> 
                    : 
                     <Grid item xs={12} sm={4}>
                    </Grid> 
                }
                <Grid item xs={12} sm={3}>
                    {
                         ( <FormControl style={{ marginRight: 10}}>
                            <Select
                                placeholder=""
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selection}
                                onChange={(event) => this.saveSelection(event.target.value)}
                            >
                                {
                                    Object.keys(this.props.levels).sort().map((level) => {
                                        return (
                                            <MenuItem value={level}>{level}</MenuItem>
                                        )
                                    })
                                }
                                
                            </Select>
                            <FormHelperText>Välj nivå</FormHelperText>
                            </FormControl>) 
                    }
                    <Tooltip
                            title={
                            <React.Fragment>
                                
                                {
                                    Object.keys(this.props.levels).sort().map((level) => {
                                        return (
                                            <div>
                                                <Typography variant="subtitle1">{level}</Typography>
                                                <Typography variant="caption">{this.props.levels[level]}</Typography>
                                            </div>
                                        )
                                    })
                                }
                            </React.Fragment>
                            }
                        >   
                        <Question />
                    </Tooltip>
                    
                </Grid>
                <Grid item xs={12} sm={1}>
                {!correct && error && <p style={{ alignSelf: 'flex-start', fontSize: 14, color: 'red'}} >Inkorrekt ifylld</p>}
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = ({ backendDataReducer, answerModelReducer }) =>  ({
        backendDataReducer, 
        answerModelReducer
  });

const mapDispatchToProps = (dispatch) => ({
    updateTimepointTask: (time, taskName, taskData) => dispatch(updateTimepointTask(time, taskName, taskData)),
    updateExtraTask: (taskName, taskData) => dispatch(updateExtraTask(taskName, taskData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputFieldClient)