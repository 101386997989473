import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import { ReactComponent as Question } from '../resources/img/question.svg';

import {connect} from 'react-redux'
import { updateTimepointTask } from '../../actions/answerModelAction';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix=""
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

class InputFields extends React.Component {
    state = {
        perWeek: '',
        level: ''
    }

    handleChange = (name, event) => {
        this.setState({
            ...this.state,
            [name]: event.target.value
        })

        this.props.store(this.props.title, name, event.target.value);
    };

    saveAnswerModel(value){
        const obj = {
            [this.props.title]: {
                perWeek: value,
                selection: 2, 
            }
        }
    }

    render() {
        console.log("props", this.props)
        const { title, values, index, canEdit, extraTasks } = this.props;
        const { selectedMunicipaly } = this.props.backendDataReducer;
        const { showSettings, showDescription } = this.props.backendDataReducer.data[selectedMunicipaly].formSettings;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle1" color="inherit" noWrap display="block" style={{ marginTop: 16 }}>
                        {title} 
                    </Typography>
                    {showDescription && 
                        <Typography variant="caption" color="inherit" noWrap display="block" style={{ marginTop: 0 }}>
                            {values.description}
                        </Typography>
                    }
                </Grid>
                {values.hasFrequency ?
                    <Grid item xs={12} sm={4}>
                    {extraTasks ? 
                        <FormControl>
                            <Select
                                placeholder=""
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.frequency}
                                onChange={(event) => console.log()}
                            >
                                <MenuItem value={"-"}>-</MenuItem>
                                <MenuItem value={"Varannan vecka"}>Varannan vecka</MenuItem>
                                <MenuItem value={"Var tredje vecka"}>Var tredje vecka</MenuItem>
                                <MenuItem value={"Var fjärde vecka"}>Var fjärde vecka</MenuItem>
                 
                            </Select>
                            <FormHelperText>Frekvens</FormHelperText>
                        </FormControl>
                        :
                        <FormControl>
                            <Select
                                placeholder=""
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.frequency}
                                onChange={(event) => console.log()}
                            >
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={0.25}>0.25</MenuItem>
                                <MenuItem value={0.33}>0.33</MenuItem>
                                <MenuItem value={0.5}>0.5</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={7}>7</MenuItem>
                            </Select>
                            <FormHelperText>Besök per vecka</FormHelperText>
                        </FormControl>
                    }
                        
                    </Grid> 
                    : 
                     <Grid item xs={12} sm={4}>
                    </Grid> 
                }
                <Grid item xs={12} sm={4}>
                    {
                         ( <FormControl style={{ marginRight: 10}}>
                            <Select
                                placeholder=""
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.level}
                                onChange={(e) => console.log()}
                            >
                                {
                                    Object.keys(this.props.levels).sort().map((level) => {
                                        return (
                                            <MenuItem value={level}>{level}</MenuItem>
                                        )
                                    })
                                }
                                
                            </Select>
                            <FormHelperText>Välj nivå</FormHelperText>
                            </FormControl>) 
                    }
                    <Tooltip
                            title={
                            <React.Fragment>
                                
                                {
                                    Object.keys(this.props.levels).sort().map((level) => {
                                        return (
                                            <div>
                                                <Typography variant="subtitle1">{level}</Typography>
                                                <Typography variant="caption">{this.props.levels[level]}</Typography>
                                            </div>
                                        )
                                    })
                                }
                            </React.Fragment>
                            }
                        >   
                            <Question />
                        </Tooltip>
                    {}
                    {canEdit ? 
                        <Button style={{ marginLeft: 50 }} variant="outlined" color="primary" onClick={() => this.props.editField(index)}>Ändra</Button>
                        :
                        <Button style={{ marginLeft: 50, opacity: 0.0 }} variant="outlined" color="primary" disabled={true} onClick={() => this.props.editField(index)}>Ändra</Button>
                    }
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = ({ backendDataReducer }) =>  ({
        backendDataReducer
  });

const mapDispatchToProps = (dispatch) => ({
    updateTimepointTask: (time, taskName, taskData) => dispatch(updateTimepointTask(time, taskName, taskData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InputFields)